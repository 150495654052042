import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/part`
const part = {
    get(model){
        return axios({
            url: url,
            params:{ keyword: model.keyword, display: model.display },
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-part\n', e.message)
            return { status: e.response.status }           
        })
    },
    find(keyword){
        return axios({
            url: `${api_domain}api/find-part`,
            params:{keyword: keyword},
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-part\n', e.message)
            return { status: e.response.status }           
        })
    },
    create(payload){
        return axios({
            url: url,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on create-part\n', e.message)
            return e.response     
        })
    },
    display(payload){
        return axios({
            url: url+'-display',
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on display-part\n', e.message)
            return { status: e.response.status }           
        })
    },
    edit(payload){
        return axios({
            url: url+'/update',
            data:payload,
            method:'PUT',
        }).catch((e)=>{
            console.log('Catch error on edit-part\n', e.message)
            return { status: e.response.status }           
        })
    },
    addStock(payload){
        return axios({
            url: `${api_domain}api/add-stock`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on add-stock\n', e.message)
            return { status: e.response.status }           
        })
    },
    delete(id){
        return axios({
            url: url+`/${id}`,
            method:'DELETE',
        }).catch((e)=>{
            console.log('Catch error on delete-part\n', e.message)
            return { status: e.response.status }           
        })
    },

}
export default part