<template>
  <div class="pa-2">
    <v-btn style="float: right" color="primary" @click="exportToPDF"
      >To PDF</v-btn
    >
    <div ref="orderlist">
      <div>
        <div class="text-left pa-1">
          <h3 class="text-decoration-underline">Order No : {{ order }}</h3>
          <span class="font-weight-bold">Name :</span> {{ mainAddress.fullName }}
          <span class="font-weight-bold">Tel :</span> {{ mainAddress.mobilePhone
          }}<br />
          <span class="font-weight-bold">Address :</span
          >{{ mainAddress.addressName }} <br />
          <span class="font-weight-bold">City :</span>{{ mainAddress.cityName }}
          <span class="font-weight-bold">Country :</span>
          {{ mainAddress.countryName }}
          <span class="font-weight-bold">Zip Code :</span>{{ mainAddress.zipCode }}
        </div>
        <div>
          <h3 class="mt-2 text-left text-decoration-underline">Order Detail</h3>
          <v-data-table
            :hide-default-footer="true"
            :items="orderList.cart.cart_item"
            :items-per-page="300"
            style="border: solid 1px red"
          >
            <template v-slot:header="{}">
              <thead class="white--text" style="background-color: #555555">
                <tr>
                  <td class="text-center">Brand</td>
                  <td class="text-center">Category</td>
                  <td class="text-center">Number</td>
                  <td class="text-center">SKU</td>
                  <td class="text-center">Name</td>
                  <td class="text-center">Quantity</td>
                  <td class="text-center">Unit Price</td>
                  <td class="text-center">Line Total</td>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  <div >
                  {{
                    item.group_part
                      ? item.group_part.motor_model.brand.name
                      : ""
                  }}
                  </div>
                   <div style="font-size:12px">
                  {{
                    item.group_part
                      ? item.group_part.motor_model.name
                      : ""
                  }}
                   </div>
                </td>
                <td class="text-center">
                  {{
                    item.group_part
                      ? getGroupPartName(item.group_part.type)
                      : ""
                  }}<br />
                  {{ item.group_part.name }}
                </td>
                <td class="text-center">
                  <v-btn outlined fab x-small>
                    {{
                      item.group_part_part.length > 0
                        ? item.group_part_part[0].number
                        : ""
                    }}
                  </v-btn>
                </td>
                <td class="text-center">{{ item.sku }}</td>
                <td class="text-center">{{ item.part.name }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="text-center">{{ item.price_per_piece }}</td>
                <td class="text-center">{{ item.total_price }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import { mapState } from "vuex";
import { part_type } from "../config";
export default {
  name: "OrderList",
  data() {
    return {
      mainAddress:null,
      partType: part_type,
    };
  },
  created() {
    this.mainAddress = this.setAddress(this.address)
  },
  methods: {
    setAddress(model) {
      if (!model) {
        return {
          fullName: "",
          addressName: "",
          cityName: "",
          countryName: "",
          zipCode: "",
          mobilePhone: "",
        };
      }
      switch (model.address_type) {
        case "thai":
          return {
            fullName: model.fullName_th,
            addressName: model.address_th,
            cityName: model.province_th,
            countryName: model.district_th,
            zipCode: model.post_code_th,
            mobilePhone: model.phone_th,
          };
        case "korea":
          return {
            fullName: model.fullName_kr,
            addressName: model.address_kr,
            cityName: model.province_kr,
            countryName: model.district_kr,
            zipCode: model.post_code_kr,
            mobilePhone: model.phone_kr,
          };
        case "shop":
          return {
            fullName: "Hello Custom",
            addressName: "258/6 Soi 12 Praram 3 Bang Khlo",
            cityName: "Bang Kho Laem, Bangkok",
            countryName: "Thailand",
            zipCode: "10120",
            mobilePhone: "+66 95 689 4280",
          };
        case "other":
          return model;
      }
    },
    getGroupPartName(id) {
      const partTypeModel = this.partType.find((x) => x.id === parseInt(id));
      return partTypeModel ? partTypeModel.name : "";
    },
    exportToPDF() {
      html2pdf(this.$refs.orderlist, {
        margin: 1,
        filename: `${this.order}.pdf`,
        image: { type: "jpeg" },
        jsPDF: {
          orientation: "p",
          unit: "mm",
          format: "a4",
        },
      });
    },
  },
  computed: {
    ...mapState({
      orderList: (state) => state.Order.order,
    }),
  },
  props: {
    itemList: {
      default: [],
    },
    order: {
      default: "",
    },
    address: {
      default: null,
    },
  },
};
</script>

<style>
</style>