import { api_domain } from '../../config'
import axios from 'axios'

const exchangeRate ={
    get(){
        return axios({
            url: `${api_domain}api/get-exchange-rate`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-exchangeRate\n', e.message)
            return { status: e.response.status }           
        })
    },
    list(){
        return axios({
            url: `${api_domain}api/list-exchange-rate`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on list-exchangeRate\n', e.message)
            return { status: e.response.status }           
        })
    },
    extraFee(){
        return axios({
            url: `${api_domain}api/get-extra-fee`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-extra-fee\n', e.message)
            return { status: e.response.status }           
        })
    },
    getVAT(){
        return axios({
            url: `${api_domain}api/get-vat`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-vat\n', e.message)
            return { status: e.response.status }           
        })
    },
    VAT(payload){
        return axios({
            url: `${api_domain}api/edit-vat`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on edit-vat\n', e.message)
            return { status: e.response.status }           
        })
    },
    editExtraFee(payload){
        return axios({
            url: `${api_domain}api/edit-extra-fee`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on edit-extra-fee\n', e.message)
            return { status: e.response.status }           
        })
    },
    updateExchangeRate(payload){
        return axios({
            url: `${api_domain}api/update-exchange-rate`,
            params:{ date: payload.date, currency: payload.currency},
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on edit-update-exchange-rate\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default exchangeRate
