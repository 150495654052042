<template>
  <v-main>
    <HeaderLabel label="SHOP CONFIG"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-row>
          <v-col cols="12">
            <v-data-table :items="brandList" style="border: solid 1px black">
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Image</td>
                    <td>Name</td>
                    <td>Description</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td align="center">
                    <v-img
                      width="100px"
                      :src="
                        item.image && item.image[0]
                          ? item.image[0]
                          : require('../assets/img/no-image.png')
                      "
                    ></v-img>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td><a @click="clickRow(item)">Edit</a></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
</style>