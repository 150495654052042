import { api_domain } from '../../config'
import axios from 'axios'

const customer= {
    get(){
        return axios({
            url: `${api_domain}api/get-user-list`,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-customer\n', e.message)
            return { status: e.response.status }           
        })
    },
    createCustomer(payload){
        return axios({
            url: `${api_domain}api/create-user`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on create-user\n', e.message)
            return { status: e.response.status }           
        })
    },
    updateCustomer(payload){
        return axios({
            url: `${api_domain}api/update-user`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on update-user\n', e.message)
            return { status: e.response.status }           
        })
    },
    deleteCustomer(payload){
        return axios({
            url: `${api_domain}api/delete-user`,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on delete-user\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default customer