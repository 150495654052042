import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Brand from './modules/Brand'
import Motor from './modules/Motor'
import CC from './modules/CC'
import Part from './modules/Part'
import GroupPart from './modules/GroupPart'
import ExchangeRate from './modules/ExchangeRate'
import Banner from './modules/Banner'
import Payment from './modules/Payment'
import Customer from './modules/Customer'
import Order from './modules/Order'
import Auth from './modules/Auth'
import Shipping from './modules/Shipping'
import Loading from './modules/Loading'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Brand,
    Motor,
    CC,
    Part,
    GroupPart,
    ExchangeRate,
    Banner,
    Payment,
    Customer,
    Order,
    Auth,
    Shipping,
    Loading
  },
  plugins: [createPersistedState()]
})