<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Brand Management</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="brand.name"
          outlined
          :rules="rules"
          label="Name"
          required
        ></v-text-field>

        <v-text-field
          v-model="brand.description"
          outlined
          label="Description"
          required
        ></v-text-field>

        <DragnDrop
          locationType="brand"
          :imgList="brand.image ? brand.image : []"
          @updateImgProduct="brand.image = $event"
        ></DragnDrop>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn
              block
              class=" cust-border-btn-color"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class=" cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import DragnDrop from "../components/input/DragnDrop";
import Service from "../services/";
export default {
  name: "BrandForm",
  props: {
    model: {
      type: Object,
    },
  },
  components: {
    DragnDrop,
  },
  data: () => ({
    valid: true,
    brand: {},
    rules: [(v) => !!v || "This Field is required"],
  }),
  created() {
    this.brand = this.model;
  },
  methods: {
    cancel(){
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.brand.id) {
          //edit
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.brand.edit(this.brand).then((res) => {
            if (res.status === 201) {
              this.$emit("SucessClick");
              this.$store.dispatch("Loading/onSetLoading", false);
            }
          });
        } else {
          //create
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.brand.create(this.brand).then((res) => {
            if (res.status === 200) {
              this.$emit("SucessClick");
              this.$store.dispatch("Loading/onSetLoading", false);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>