
<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Account Payment</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              outlined
              :rules="rules"
              v-model="account.nice_name"
              label="Bank Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              outlined
              :rules="rules"
              v-model="account.account_name"
              label="Account Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-select
              outlined
              :items="currency"
              v-model="account.currency"
              :rules="rules"
              label="Currency"
              required
            ></v-select>
          </v-col>
          <v-col class="pt-3 pb-0" cols="8">
            <v-text-field
              outlined
              :rules="rules"
              v-model="account.account_number"
              label="Account Number"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Service from "../services/";
export default {
  name: "UserAccountForm",
  props: {
    model: {
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    valid: true,
    account: {},
    currency: ["THB", "KRW"],
    rules: [(v) => !!v || "This Field is required"],
  }),
  created() {
    this.account.id = this.model.id;
    this.account.account_name = this.model.account_name;
    this.account.account_number = this.model.account_number;
    this.account.nice_name = this.model.nice_name;
    this.account.currency = this.model.currency;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.account.id) {
          //edit
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.payment.updateAccount(this.account).then((res) => {
            if (res.status === 201) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            }
          });
        } else {
          //create
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.payment.createAccount(this.account).then((res) => {
            if (res.status === 200) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>