
<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Shipping Fee</h3>
    </v-alert>
    <v-alert v-if="error" dense outlined type="error">
      {{ error }}
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="6">
            <v-autocomplete
              outlined
              v-model="shipping.country"
              :items="countries"
              :rules="rules"
              item-text="name"
              item-value="name"
              label="Country Name"
              required
            ></v-autocomplete>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              outlined
              v-model="shipping.fee"
              type="number"
              :rules="rules"
              label="Fee (Bath)"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="12">
            <v-text-field
              outlined
              v-model="shipping.weight"
              type="number"
              :rules="rules"
              label="Weight (kg)"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { countries } from "@/assets/data/countries";
import Service from "../services/";
export default {
  name: "ShippingForm",
  props: {
    model: {
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    valid: true,
    error: "",
    countries: countries,
    shipping: {},
    rules: [(v) => !!v || "This Field is required"],
  }),
  created() {
    this.shipping.id = this.model.id;
    this.shipping.weight = this.model.weight;
    this.shipping.country = this.model.country;
    this.shipping.fee = this.model.fee;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.shipping.id) {
          //edit
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.shipping.edit(this.shipping).then((res) => {
            if (res.status === 201) {
              this.error = "";
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            } else {
              this.error = res.data.message;
            }
          });
        } else {
          //create
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.shipping.create(this.shipping).then((res) => {
            if (res.status === 200) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>