<template>
  <div>
    <v-card class="mx-auto">
      <v-toolbar color="black" dark>
        <v-toolbar-title>Part List</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout class="mt-4">
          <v-flex>
            <v-text-field
              dense
              v-model="keyword"
              placeholder="SKU Or Name Of Parts"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex>
            <v-btn @click="search" class="cust-btn-color"> Search </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-list dense>
        <v-list-item-group multiple color="primary">
          <v-list-item
            @click="item.active = !item.active"
            v-for="(item, i) in partList"
            :key="i"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-flex>
                <v-list-item-content>
                  <v-list-item-title
                    ><h2>{{ item.sku }}</h2>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    ><h4>{{ item.name }}</h4></v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><h4>{{ item.price + "  Bath" }}</h4></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block class="cust-btn-color" @click="confirm">
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PartList",
  data() {
    return {
      selectedItem: null,
      keyword: null,
    };
  },
  computed: {
    ...mapState({
      partList: (state) => state.Part.resultPart,
    }),
  },
  created() {
    this.$store.commit("Part/clearResultPart");
  },
  methods: {
    cancel() {
      this.$emit("cancelClick");
    },
    confirm() {
      let isactive = this.partList.filter((x) => x.active);
      if (isactive.length > 0) {
        this.$emit("SucessClick", isactive);
      } else {
        this.$swal.fire({
          ...this.$swalError,
          text: "Please Select Part or Cancel to Exit",
        });
      }
    },
    search() {
      if (this.keyword && this.keyword != "") {
        this.$store.dispatch("Part/getResultPartList", this.keyword);
      }
    },
    itemQuantityInCart(id) {
      let item = this.inCart.filter((x) => x.product_stock_id === id);
      if (item.length > 0) {
        return item[0].quantity;
      }
      return 0;
    },
  },
};
</script>

<style>
</style>