import Service from '../../services'

const state={
    exchangeRate:{},
    listExchangeRate:[],
    vat:null,
    extraFee:null
}
const getters={}
const actions={
    getExchangeRate({commit}){
        return Service.exchangeRate.get().then((res)=>{
            if(res.status === 200){
                commit('setExchangeRate', res.data)
            }
        })
    },
    getExtraFee({commit}){
        return Service.exchangeRate.extraFee().then((res)=>{
            if(res.status === 200){
                console.log(res);
                commit('setExtraFee', res.data)
            }
        })
    },
    getVAT({commit}){
        return Service.exchangeRate.getVAT().then((res)=>{
            if(res.status === 200){
                commit('setVAT', res.data)
            }
        })
    },
    getListExchangeRate({commit}){
        return Service.exchangeRate.list().then((res)=>{
            if(res.status === 200){
                commit('setListExchangeRate', res.data)
            }
        })
    }
}
const mutations={
    setExtraFee(state,data){
        state.extraFee = data;
    },
    setVAT(state,data){
        state.vat = data*100;
    },
    setExchangeRate(state,data){
        state.exchangeRate = data;
    },
    setListExchangeRate(state,data){
        state.listExchangeRate = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}