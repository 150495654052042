<template>
  <v-tooltip right color="rgb(74, 93, 107)">
    <template v-slot:activator="{ on }">
      <v-text-field
        :id="id"
        :outlined="!flat"
        :flat="flat"
        :solo="solo"
        dense
        :type="type"
        :oninput="inputRule"
        :disabled="disabled"
        :readonly="readonly"
        hide-details
        :class="{ centerText: center, endText: end, 'text-field-theme': !solo }"
        :height="height"
        :style="{ width: width }"
        :background-color="backgroundColor"
        :full-width="fullWidth"
        :placeholder="placeholder"
        :prepend-inner-icon="prependInIcon"
        :value="value"
        v-on="tooltip ? on : null"
        @keypress.enter="eventKeyEnter"
        @change="eventChange"
      ></v-text-field>
    </template>
    <div>
      <div v-for="(item, i) in Object.keys(tooltip)" :key="i">
        <span class="text-capitalize">{{ item }}</span
        >: {{ Object.values(tooltip)[i] }}
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    id: {
      default: undefined
    },
    placeholder: {
      default: ''
    },
    fullWidth: {
      default: false
    },
    width: {
      default: 'unset'
    },
    height: {
      default: '32px'
    },
    backgroundColor: {
      default: '#fff'
    },
    prependInIcon: {
      default: ''
    },
    center: {
      default: false
    },
    end: {
      default: false
    },
    value: {
      default: ''
    },
    tooltip: {
      default: ''
    },
    flat: {
      default: false
    },
    solo: {
      default: false
    },
    type: {
      default: 'text'
    },
    inputRule: {
      default: null
    },
    disabled: {
      default: false
    },
    readonly: {
      default: false
    }
  },
  methods: {
    eventChange(val) {
      this.$emit('onChange', val)
    },
    eventKeyEnter() {
      this.$emit('onEnter')
    }
  }
}
</script>

<style scoped>
.text-field-theme {
  border-radius: 2px;
  border-color: #898989;
}
.centerText >>> input {
  text-align: center;
}
.endText >>> input {
  text-align: end;
}
</style>
