<template>
  <div class="d-flex justify-center align-center" style="height:100vh; background: white;">
  </div>
</template>

<script>
export default {
  name: "Home",
  created(){

  },
  components: {},
};
</script>
