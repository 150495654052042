import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/payment`
const payment = {
    bankList() {
        return axios({
            url: url + '/bank-list',
            method: 'GET',
        }).catch((e) => {
            console.log('Catch error on bank-list\n', e.message)
            return { status: e.response.status }
        })
    },
    createAccount(payload) {
        return axios({
            url: url + '/create-account',
            data: payload,
            method: 'POST',
        }).catch((e) => {
            console.log('Catch error on create-account\n', e.message)
            return { status: e.response.status }
        })
    },
    updateAccount(payload) {
        return axios({
            url: url + '/update-account',
            data: payload,
            method: 'POST',
        }).catch((e) => {
            console.log('Catch error on update-account\n', e.message)
            return { status: e.response.status }
        })
    },
    deleteAccount(payload) {
        return axios({
            url: url + '/delete-account',
            data: payload,
            method: 'POST',
        }).catch((e) => {
            console.log('Catch error on update-account\n', e.message)
            return { status: e.response.status }
        })
    }
}
export default payment