import Service from '../../services'

const state = {
    order:null,
    orders: [],
    pagination:{
        page: 1,
        itemsPerPage: 15,
        pageStart: 1,
        pageStop: 1,
        pageCount: 0
    },
    paymentStatus:['WAITING','CONFIRM'],
    shippingStatus:['TO BE SHIP','TO RECEIVE','COMPLETE']
}

const getters = {}

const actions = {
    onGetOrderDetail ({ commit }, data){
        return Service.order.getOrderDetail(data).then(res => {
            if (res.status === 200) {
                commit('setOrder', res.data)
            } else {
                commit('clearOrders')
            }
        })
    },

    onGetPaginateOrder({ commit },payload) {
        return Service.order.getPaginateOrder(payload.page, payload.row, payload.checkout, payload.pay_by, payload.token, payload.keyword).then((res) => {
            if (res.status === 200) {
                const data = res.data.data
                const pagination = {
                    page: res.data.current_page,
                    itemsPerPage: res.data.per_page,
                    pageStart: res.data.from,
                    pageStop: res.data.last_page,
                    pageCount: Math.ceil(res.data.total / payload.row)
                }
                commit('setPaginateOrders', data)
                commit('setPagination', pagination)
                return { data:data,pagination:pagination}
            }
        })
    },
}

const mutations = {
    setOrder (state, data) {
        state.order = data
    },
    setOrders (state, data) {
        state.orders = data
    },
    setPagination(state,data){
        state.pagination = data
    },
    setPaginateOrders (state, data){
        state.orders = data
    },
    clearOrders (state) {
        state.orders = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
