import { api_domain } from '../../config'
import axios from 'axios'

const order = {
    getPaginateOrder(page,row,checkout,pay_by,token, keyword){
        return axios({
            url: api_domain + 'api/get-all-paginate-orders',
            data: { 
                access_token: token,
                page: page,
                row: row,
                checkout: checkout,
                pay_by: pay_by,
                keyword: keyword
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on get-all-paginate-orders\n', e.message)
            return { status: e.response.status }
        })
    },
    getExcelOrder(){
        return axios({
            url: api_domain + 'api/export-order-excel',
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on get-export-order-excel\n', e.message)
            return { status: e.response.status }
        })
    },
    getOrderDetail(data) {
        return axios({
            url: api_domain + 'api/get-orders-detail',
            data: {
                access_token: data.access_token,
                order_id: data.order_id
            },
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on getOrderDetail\n', e.message)
            return { status: e.response.status }
        })
    },
    editShippingStatus(order_id,shipping_status){
        return axios({
            url: api_domain + 'api/update-shipping',
            data: { order_id: order_id,shipping_status: shipping_status},
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on editShippingStatus\n', e.message)
            return { status: e.response.status }
        })
    },
    updateTrackingNumber(model){
        return axios({
            url: api_domain + 'api/update-tracking-number',
            data: model,
            method: 'POST'
        }).catch((e) => {
            console.log('Catch error on updateTrackingNumber\n', e.message)
            return { status: e.response.status }
        })
    },
}

export default order
