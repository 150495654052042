<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Shipping Fee"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-end mb-7>
          <v-flex shrink>
            <v-btn
              @click="clickRow({ id: null, country: null, weight: null, fee: null })"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn>
          </v-flex>
        </v-layout>

        <v-row>
          <v-col cols="12">
            <v-container>
              <v-data-table
                :items="shippingList"
                style="border: solid 1px black"
              >
                <template v-slot:header="{}">
                  <thead class="white--text" style="background-color: #555555">
                    <tr>
                      <td>Country</td>
                      <td>Weight (kg.)</td>
                      <td>Fee (Bath)</td>
                      <td></td>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.country }}</td>
                    <td>{{ item.weight }}</td>
                    <td>{{ item.fee }}</td>
                    <td>
                      <a @click="clickRow(item)">Edit</a> |
                      <a class="red--text" @click="removeAccount(item)"
                        >Delete</a
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" @close="SucessClick" max-width="700" persistent>
      <div v-if="dialog" style="background-color: white">
        <ShippingForm @SucessClick="SucessClick" :model="row"></ShippingForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import ShippingForm from "../components/ShippingForm";
import HeaderLabel from "../components/HeadetLabel";
import Service from '../services'
import { mapState } from "vuex";
export default {
  components: {
    HeaderLabel,
    ShippingForm,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      shippingList: (state) => state.Shipping.shippingList,
    }),
  },
  methods: {
    async removeAccount(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: "Are you sure to remove this Shipping Fee?",
      });
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading",true);
        Service.shipping.delete(item).then((res)=>{
          if(res.status === 201){
            this.$store.dispatch("Loading/onSetLoading",false);
            this.$store.dispatch("Shipping/getShippingList");
          }
        })
      }
    },
    SucessClick() {
      this.$store.dispatch("Shipping/getShippingList");
      this.dialog = false;
    },
    clickRow(item) {
      this.row = item;
      this.dialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("Shipping/getShippingList");
  },
};
</script>

<style>
</style>