import brand from './api/brand'
import motor from './api/motor'
import upload from './api/upload'
import cc from './api/cc'
import part from './api/part'
import groupPart from './api/groupPart'
import exchangeRate from './api/exchangeRate'
import banner from './api/banner'
import payment from './api/payment'
import customer from './api/customer'
import order from './api/order'
import auth from './api/auth'
import shipping from './api/shipping'

export default{
    brand,
    motor,
    upload,
    cc,
    part,
    groupPart,
    exchangeRate,
    banner,
    payment,
    customer,
    order,
    auth,
    shipping
}