<template>
  <v-btn @click="queryExcel" color="success"
    >Export To Excel<v-icon>mdi-file-excel</v-icon>
  </v-btn>
</template>

<script>
import XLSX from 'xlsx'
export default {
  name: 'Excel',
  props: {
    dataSource: {
      type: Array,
      default: function () {
        return []
      }
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  watch: {
    dataSource: function () {
     this.toExcel()
    }
  },
  methods: {
    queryExcel() {
      this.$emit('queryJSON')
    },
    toExcel() {
      if (this.dataSource.length) {
        const dataWS = XLSX.utils.json_to_sheet(this.dataSource)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb, `${this.fileName}.xlsx`)
      }
    }
  }
}
</script>

<style>
</style>