import Service from '../../services'

const state={
    shippingList:[],
}
const getters={}
const actions={
    getShippingList({commit}){
        return Service.shipping.get().then((res)=>{
            if(res.status === 200){
                commit('setShippingList', res.data)
            }
        })
    },
}
const mutations={
    setShippingList(state,data){
        state.shippingList = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}