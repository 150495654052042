import Service from '../../services'

const state={
    ccList:[]
}
const getters={}
const actions={
    getCCList({commit},model){
        return Service.cc.get(model).then((res)=>{
            if(res.status === 200){
                commit('setCCList', res.data)
            }
        })
    }
}
const mutations={
    setCCList(state,data){
        state.ccList = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}