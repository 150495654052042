<template>
  <v-main style="background-color: #e5e5e5">
    <v-layout style="min-height: 100vh">
      <v-flex shrink style="background-color: #fff">
        <SideBar></SideBar>
      </v-flex>
      <v-flex>
          <router-view></router-view>
      </v-flex>
    </v-layout>
    <!-- <Footer type="website"></Footer> -->
  </v-main>
</template>

<script>
import SideBar from "../components/SideBar";
export default {
  name: "MainLayout",
  components: {
    SideBar,
  },
};
</script>

<style>
</style>