<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Exchange Rate"></HeaderLabel>
    <v-layout justify-start mt-5>
      <v-flex pa-4>
        <v-row>
          <v-col
            v-for="(item, index) in exchangeRate"
            :key="index"
            lg="6"
            md="12"
            cols="12"
          >
            <v-card class="mx-auto" max-width="400">
              <v-card-text>
                <p class="display-1 text--primary">{{ item.currency }}</p>
                <v-layout justify-space-between>
                  <v-flex shrink> Description </v-flex>
                  <v-flex shrink>
                    {{ item.description }}
                  </v-flex>
                </v-layout>
                <v-layout justify-space-between>
                  <v-flex shrink> BOT Rate + Extra </v-flex>
                  <v-flex shrink>
                    {{ item.full_sell_rate }}
                  </v-flex>
                </v-layout>
                <v-layout justify-space-between>
                  <v-flex shrink> BOT Rate </v-flex>
                  <v-flex shrink>
                    {{ item.raw_selling }}
                  </v-flex>
                </v-layout>
                <v-layout justify-space-between>
                  <v-flex shrink> Date </v-flex>
                  <v-flex shrink>
                    {{ item.date }}
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row justify-lg="end" justify-md="center" class="mb-2">
              <v-col md="4" sm="12" lg="2">
                <v-btn @click="dialog = true" class="cust-btn-color"
                  >Edit Extra Rate</v-btn
                >
              </v-col>
              <v-col md="4" sm="12" lg="2">
                <v-btn @click="dialogVat = true" class="cust-btn-color"
                  >Edit VAT</v-btn
                >
              </v-col>
              <v-col md="4" sm="12" lg="3">
                <v-btn @click="dialogExchangeRate = true" class="cust-btn-color"
                  >Update Exchange Rate</v-btn
                >
              </v-col>
            </v-row>
            <v-data-table
              :items="listExchangeRate"
              style="border: solid 1px black"
            >
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Name</td>
                    <td>BOT Rate</td>
                    <td>BOT Rate + Extra</td>
                    <td>Buying Transfer</td>
                    <td>Selling</td>
                    <td>Mid Rate</td>
                    <td>Buying Sight</td>
                    <td>Date</td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.currency }}</td>
                  <td>{{ item.raw_selling }}</td>
                  <td>{{ item.full_sell_rate }}</td>
                  <td>{{ item.raw_buying_transfer }}</td>
                  <td>{{ item.raw_selling }}</td>
                  <td>{{ item.raw_mid_rate }}</td>
                  <td>{{ item.raw_buying_sight }}</td>
                  <td>{{ item.date }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogVat" max-width="300">
      <div v-if="dialogVat" style="background-color: white">
        <HeaderLabel label="Edit VAT"></HeaderLabel>
        <v-container>
          <v-row align-content-center>
            <v-col cols="8">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  :rules="rules"
                  v-model="vat"
                  type="number"
                  outlined
                  required
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="4">
              <div>% (Percent)</div>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                class="cust-border-btn-color"
                @click="dialogVat = false"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="!valid"
                block
                class="cust-btn-color"
                @click="confirmVAT"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="300">
      <div v-if="dialog" style="background-color: white">
        <HeaderLabel label="Edit Extra Fee"></HeaderLabel>
        <v-container>
          <v-row align-content-center>
            <v-col cols="8">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  dense
                  :rules="rules"
                  v-model="extraFeePrice"
                  type="number"
                  outlined
                  required
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="4">
              <div>% (Percent)</div>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                class="cust-border-btn-color"
                @click="dialog = false"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="!valid"
                block
                class="cust-btn-color"
                @click="confimExtraFee"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogExchangeRate" max-width="400">
      <div v-if="dialogExchangeRate" style="background-color: white">
        <HeaderLabel label="Update Exchange Rate"></HeaderLabel>
        <v-alert v-if="error" dense outlined type="error">
          {{ error }}
        </v-alert>
        <v-container>
          <v-form ref="formUpdate" v-model="valid" lazy-validation>
            <v-row align-content-center>
              <v-col cols="6">
                <v-select
                  dense
                  :rules="rules"
                  v-model="currency"
                  :items="currencyList"
                  outlined
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-dialog
                  ref="dialog_date"
                  v-model="dialogDate"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      class="centered-input"
                      v-model="date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dialogDate = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog_date.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  class="cust-border-btn-color"
                  @click="dialogExchangeRate = false"
                >
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  :disabled="!valid"
                  block
                  class="cust-btn-color"
                  @click="confirmUpdateRate"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import Service from "../services/";
import HeaderLabel from "../components/HeadetLabel";
export default {
  name: "ExchangeRate",
  components: {
    HeaderLabel,
  },
  computed: {
    ...mapState({
      exchangeRate: (state) => state.ExchangeRate.exchangeRate,
      listExchangeRate: (state) => state.ExchangeRate.listExchangeRate,
      extraFee: (state) => state.ExchangeRate.extraFee,
      vatPrice: (state) => state.ExchangeRate.vat,
    }),
  },
  async created() {
    await this.$store.dispatch("ExchangeRate/getExchangeRate");
    await this.$store.dispatch("ExchangeRate/getListExchangeRate");
    await this.$store.dispatch("ExchangeRate/getExtraFee");
    await this.$store.dispatch("ExchangeRate/getVAT");
    this.extraFeePrice = this.extraFee.value;
    this.vat = this.formatNumber(this.vatPrice,2);
  },
  methods: {
    formatNumber(x, toFixed = 0) {
      if (!x && x != 0) {
        return x;
      }
      if (toFixed === 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else if (toFixed === 2) {
        return x.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
    confimExtraFee() {
      this.$store.dispatch("Loading/onSetLoading", true);
      if (this.$refs.form.validate()) {
        Service.exchangeRate
          .editExtraFee({ rate: this.extraFeePrice })
          .then(async (res) => {
            if (res.status === 200) {
              await this.$store.dispatch("ExchangeRate/getExchangeRate");
              await this.$store.dispatch("ExchangeRate/getListExchangeRate");
              await this.$store.dispatch("ExchangeRate/getExtraFee");
              this.extraFeePrice = this.extraFee.value;
              this.dialog = false;
            } else {
              this.$swal.fire({
                ...this.$swalError,
                text: "Update Rate Error",
              });
            }
          });
      }
      this.$store.dispatch("Loading/onSetLoading", false);
    },
    confirmVAT() {
      this.$store.dispatch("Loading/onSetLoading", true);
      if (this.$refs.form.validate()) {
        Service.exchangeRate.VAT({ vat: this.vat }).then(async (res) => {
          if (res.status === 200) {
            await this.$store.dispatch("ExchangeRate/getVAT");
            this.vat = this.formatNumber(this.vatPrice,2);
            this.dialogVat = false;
          } else {
            this.$swal.fire({
              ...this.$swalError,
              text: "Update VAT Error",
            });
          }
        });
      }
      this.$store.dispatch("Loading/onSetLoading", false);
    },
    confirmUpdateRate() {
      this.$store.dispatch("Loading/onSetLoading", true);
      if (this.$refs.formUpdate.validate()) {
        Service.exchangeRate
          .updateExchangeRate({
            date: this.date,
            currency: this.currency,
          })
          .then(async (res) => {
            if (res.status === 200) {
              await this.$store.dispatch("ExchangeRate/getExchangeRate");
              await this.$store.dispatch("ExchangeRate/getListExchangeRate");
              this.dialogExchangeRate = false;
              this.error = "";
            } else if (res.status === 204) {
              this.error = `No Exchange Rate Avialable On ${this.date}`;
              this.$store.dispatch("Loading/onSetLoading", false);
            } else {
              this.$swal.fire({
                ...this.$swalError,
                text: "Update Rate Error",
              });
            }
          });
      }
      this.$store.dispatch("Loading/onSetLoading", false);
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      error: "",
      date: new Date().toISOString().substr(0, 10),
      currency: null,
      vat: null,
      dialogDate: false,
      dialogVat: false,
      currencyList: ["USD", "KRW"],
      dialogExchangeRate: false,
      extraFeePrice: null,
      rules: [(v) => !!v || "This Field is required"],
    };
  },
};
</script>

<style>
</style>