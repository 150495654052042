import Service from '../../services'

const state={
    customerList:[]
}

const getters={}
const actions={
    getCustomerList({commit}){
        return Service.customer.get().then((res)=>{
            if(res.status === 200){
                commit('setCustomerList', res.data)
            }
        })
    }
}

const mutations={
    setCustomerList(state,data){
        state.customerList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}