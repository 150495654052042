import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/MainLayout.vue'
import Brand from '../views/Brand'
import MotorModel from '../views/MotorModel'
import GroupPart from '../views/GroupPart'
import CC from '../views/CC'
import Part from '../views/Part'
import ExchangeRate from '../views/ExchangeRate'
import Banner from '../views/Banner'
import ShopConfig from '../views/ShopConfig'
import AccountPayment from '../views/AccountPayment'
import UserAccount from '../views/UserAccount'
import CustomerOrder from '../views/CustomerOrder'
import Shipping from '../views/Shipping'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main-layout',
    component: MainLayout,
    children:[
      {
        path:'/',
        name:'brand',
        component:Brand
      },
      {
        path:'/brand',
        name:'brand',
        component:Brand
      },
      {
        path:'/motor-model',
        name:'motor-model',
        component:MotorModel
      },
      {
        path:'/cc',
        name:'cc',
        component:CC
      },
      {
        path:'/group-part',
        name:'group-part',
        component:GroupPart
      },
      {
        path:'/part',
        name:'part',
        component:Part
      },
      {
        path:'/exchange-rate',
        name:'exchange-rate',
        component:ExchangeRate
      },
      {
        path:'/banner',
        name:'banner',
        component:Banner
      },
      {
        path:'/shop-config',
        name:'shop-config',
        component:ShopConfig
      },
      {
        path:'/account-payment',
        name:'account-payment',
        component:AccountPayment
      },
      {
        path:'/user-account',
        name:'user-account',
        component:UserAccount
      },
      {
        path:'/customer-order',
        name:'customer-order',
        component:CustomerOrder
      },
      {
        path:'/shipping-fee',
        name:'shipping-fee',
        component:Shipping
      },
    ]
  },
  {
    path:'/home',
    name:'home',
    component:Home
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
