<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Stock Management</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              v-model="part.sku"
              readonly
              outlined
              label="SKU"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              readonly
              v-model="part.name"
              outlined
              label="Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              readonly
              v-model="part.stock"
              outlined
              label="Remain Stock"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              outlined
              v-model="part.amount"
              type="number"
              :rules="rules"
              label="Add Stock"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :disabled="!valid" @click="validate" block class="cust-btn-color">
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Service from "../services/";
export default {
  name: "PartStockForm",
  props: {
    model: {
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    valid: true,
    part: {
      id: null,
      sku: null,
      name: null,
      stock: null,
      amount: null,
    },
    rules: [(v) => !!v || "This Field is required"],
  }),
  mounted() {
    this.part.id = this.model.id;
    this.part.sku = this.model.sku;
    this.part.name = this.model.name;
    this.part.stock = this.model.product_stock.stock;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.part.id) {
          //edit
          this.$store.dispatch("Loading/onSetLoading", true);
          Service.part.addStock(this.part).then((res) => {
            if (res.status === 200) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick");
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>