<template>
  <v-select
    class="mr-2"
    outlined
    dense
    v-model="status"
    @change="onChange"
    item-text="item"
    item-value="value"
    :items="display"
    label="Display"
  ></v-select>
</template>

<script>
export default {
  data() {
    return {
      status: 1,
      display: [
        { item: "Show", value: 1 },
        { item: "Hide", value: 0  },
        { item: "All", value: null },
      ],
    };
  },
  methods: {
    onChange(event) {
      this.status = event;
      this.$emit("onChange", event);
    },
  },
};
</script>

<style>
</style>