<template>
  <div>
    <div >
      <!-- Sign in -->
      <div>
        <div class="text-center mt-5 txt-m-large mb-3 align-item-center">
          ADMIN LOGIN
        </div>
        <v-alert v-if="error" dense outlined type="error">
          {{ error }}
        </v-alert>
        <div class="mt-5 mb-5">
          <TextField
            type="email"
            placeholder="Email"
            @onChange="signIn.email = $event"
            :value="signIn.email"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <div class="mb-5">
          <TextField
            type="password"
            placeholder="Password"
            @onChange="signIn.password = $event"
            :value="signIn.password"
            :disabled="onlyFacebookLogin"
          ></TextField>
        </div>
        <v-layout :column="$vuetify.breakpoint.smAndDown">
          <v-btn @click="eventLogin" class="cust-btn-color mt-3" block>
            Log in
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '../components/input/TextField'
import { mapState } from 'vuex'

export default {
  components: {
    TextField
  },
  props: {
    signupEmail: {
      default: ''
    },
    signupDetail: {
      default: null
    },
    onlyFacebookLogin: {
      default: false
    },
    error: {
      default: null
    }
  },

  data() {
    return {
      signupSection: false,
      isLoading: false,
      signIn: {
        email: '',
        password: ''
      },
      confirmPassword: ''
    }
  },
  computed: {
    ...mapState({
      userDetail: (state) => state.Auth.userDetail
    })
  },
  methods: {
    eventCreateAcc() {
      if (this.signupEmail) {
        this.signupSection = true
      } else {
        this.$swal.fire({
          ...this.$swalError,
          text: 'Please correct your email'
        })
      }
    },
    eventLogin() {
      this.$emit('onLogin', this.signIn)
    },
    eventChangeEmail(input) {
      this.$emit('onChangeSignupEmail', input)
    },
    onRegister() {
      if (!this.validateRegister()) {
        this.$swal.fire({
          ...this.$swalError,
          text:
            'Please correct your information and re-type password to confirm'
        })
        this.confirmPassword = ''
        return
      }
      // register api
      //
      this.$emit('onSignupSuccess', this.signupDetail)
    },
    validateRegister() {
      let pass = true
      if (!this.signupDetail.firstname || !this.signupDetail.lastname) {
        pass = false
      }
      if (this.confirmPassword !== this.signupDetail.password) {
        pass = false
      }
      return pass
    },
    eventSetPassword(val) {
      this.$emit('setPassword', val)
    },
    eventSetFirstname(val) {
      this.$emit('setFirstname', val)
    },
    eventSetLastname(val) {
      this.$emit('setLastname', val)
    },
    eventSetEmail(val) {
      this.$emit('setEmail', val)
    },
    checkoutAsGuest(){
      this.$emit('checkoutAsGuest')
    },
  }
}
</script>

<style scoped lang="scss">
.layout-border {
  border: solid 2px #c4c4c4;
}
.bd-selectbox {
  display: inline-block;
  &.day {
    width: 70px;
  }
  &.month {
    width: 90px;
  }
  &.year {
    width: 90px;
  }
}
</style>
