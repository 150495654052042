//production
export const api_domain = 'https://api.thaibikeparts.com/'
//dev
// export const api_domain = 'http://127.0.0.1:8000/'
// TEST API
// export const api_domain = 'https://apihellocst.ksta.co/'

export const part_type = [
  { id: 1, name: 'Parts Manual' },
  { id: 2, name: 'Parts Color' }
]
export const currency_symbol = {'USD':'$' ,'KRW':'₩'  ,'THB':'฿'}
export const language_symbol = {'USD':'ENGLISH' ,'KRW':'한국어'  ,'THB':'ภาษาไทย'}
