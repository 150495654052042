
<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">User Account</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              outlined
              v-model="account.firstname"
              :rules="rules"
              label="First Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              outlined
              v-model="account.middlename"
              label="Middle Name"
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              outlined
              v-model="account.lastname"
              :rules="rules"
              label="Last Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              v-model="account.company"
              outlined
              label="Company"
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              v-model="account.email"
              outlined
              :rules="rules"
              label="Email"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field
              v-model="account.fax"
              outlined
              label="Fax"
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="8">
            <v-text-field
              v-model="account.phone"
              outlined
              label="Phone"
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-select
              outlined
              v-model="account.level"
              :items="level"
              :rules="rules"
              item-text="value"
              item-value="key"
              label="Level"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Service from "../services";
export default {
  name: "UserAccountForm",
  props: {
    model: {
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    valid: true,
    account: {},
    level: [
      { key: 1, value: "Customer" },
      { key: 2, value: "Admin" },
    ],
    rules: [(v) => !!v || "This Field is required"],
  }),
  created() {
    this.account.id = this.model.id;
    this.account.firstname = this.model.firstname;
    this.account.middlename = this.model.middlename;
    this.account.lastname = this.model.lastname;
    this.account.company = this.model.company;
    this.account.phone = this.model.phone;
    this.account.fax = this.model.fax;
    this.account.email = this.model.email;
    this.account.phone = this.model.phone;
    this.account.level = this.model.level;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick");
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.account.id) {
          this.$store.dispatch("Loading/onSetLoading", true);
          //edit
          Service.customer.updateCustomer(this.account).then((res) => {
            if (res.status === 200) {
              this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            }
            else{
              this.$store.dispatch("Loading/onSetLoading", false);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>