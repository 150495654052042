<template>
  <v-main style="height: 100%">
    <HeaderLabel label="CC"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-space-between mb-7>
          <v-flex shrink>
            <v-layout>
              <v-flex>
                <v-layout>
                  <v-flex>
                    <FilterDisplay @onChange="filter = $event"></FilterDisplay>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      class="mr-2"
                      v-model="keyword"
                      dense
                      outlined
                      label="Keyword"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      @click="search"
                      depressed
                      dense
                      large
                      color="#c80404"
                      class="text-start white--text"
                    >
                      Search
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink>
            <v-btn
              @click="clickRow({})"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn>
          </v-flex>
        </v-layout>
        <v-row>
          <v-col cols="12">
            <v-data-table :items="ccList" style="border: solid 1px black">
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Name</td>
                    <td>Description</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr :class="{ itemHide: !item.is_active }">
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>
                    <a @click="clickRow(item)">Edit</a> |
                    <a
                      @click="toggleDisplay(item)"
                      :class="[item.is_active ? 'red--text' : 'green--text']"
                    >
                      {{ item.is_active ? "Hide" : "Show" }}
                    </a>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" @close="SucessClick" max-width="700" persistent>
      <div v-if="dialog" style="background-color: white">
        <CCForm @SucessClick="SucessClick" :model="row"></CCForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import HeaderLabel from "../components/HeadetLabel";
import Service from "../services";
import { mapState } from "vuex";
import CCForm from "../components/CCForm";
import FilterDisplay from "../components/FilterDisplay";
export default {
  name: "CC",
  data() {
    return {
      row: {},
      keyword: null,
      filter:1,
      dialog: false,
    };
  },
  components: {
    CCForm,
    FilterDisplay,
    HeaderLabel,
  },
  methods: {
    search() {
      this.$store.dispatch("CC/getCCList", {keyword:this.keyword, display:this.filter});
    },
    clickRow(item) {
      this.row = item;
      this.dialog = true;
    },
    async toggleDisplay(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to ${item.is_active ? "hide" : "show"} this CC?`,
      });
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading", true);
        Service.cc.display(item).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$store.dispatch("CC/getCCList", {keyword:this.keyword, display:this.filter});
          }
        });
      }
    },
    SucessClick() {
      this.dialog = false;
      this.$store.dispatch("CC/getCCList", {keyword:this.keyword, display:this.filter});
    },
  },
  async created() {
    await this.$store.dispatch("CC/getCCList", {keyword:this.keyword, display:this.filter});
  },
  computed: {
    ...mapState({
      ccList: (state) => state.CC.ccList,
    }),
  },
};
</script>

<style>
</style>