
const state = {
    isLoading:false
}

const getters = {}
const actions = {
    onSetLoading({ commit },data) {
        commit('setLoading',data)
    }
}
const mutations = {
    setLoading(state,data){
        state.isLoading = data; 
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}