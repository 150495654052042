import Service from '../../services'

const state ={
    bankFrom:[],
    bankTo:[],
}

const getters={}
const actions={
    getBankList({commit}){
        return Service.payment.bankList().then((res)=>{
            if(res.status === 200){
                commit('setBankList', res.data)
            }
        })
    }
}
const mutations={
    setBankList(state, data){
        state.bankFrom = data.from;
        state.bankTo = data.to;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}