<template>
  <v-main>
    <HeaderLabel label="Banner"></HeaderLabel>
    <v-container>
      <v-row class="grey lighten-5 mb-2">
        <v-col cols="12">
          <div class="text-left ">
            <h3>Youtube Link</h3>
          </div>
          <v-text-field
            v-model="link"
            dense
            outlined
            block
            required
          ></v-text-field>
          <div class="text-right">
            <v-btn @click="saveLink" class="cust-btn-color"> Save </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col
          class="grey lighten-5 my-2"
          cols="12"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <div class="text-right">
            <v-btn class="cust-border-btn-color ma-4" @click="remove(index)"
              >remove</v-btn
            >
          </div>
          <DragnDrop
            locationType="banner"
            :imgList="item.image ? [item.image] : []"
            @updateImgProduct="uploadImage(index, $event)"
          ></DragnDrop>
          <div class="text-left mt-5">
            <h3>Linked Image URL</h3>
          </div>
          <v-text-field
            v-model="bannerList[index].url"
            dense
            outlined
            block
            required
          ></v-text-field>
          <!-- <v-file-input
            @change="uploadImage($event, index)"
            v-else
            v-model="item.image"
            accept="image/*"
            label="Click Here To Browse Image"
          ></v-file-input> -->
        </v-col>
        <v-col cols="12">
          <v-btn block class="cust-btn-color mt-10" @click="add">
            + Add Banner</v-btn
          >
        </v-col>

        <v-col cols="12">
          <div class="row py-5">
            <v-col offset="6">
              <v-btn block class="cust-border-btn-color mr-2"> Cancel </v-btn>
            </v-col>
            <v-col>
              <v-btn @click="saveBanner" block class="cust-btn-color">
                Save
              </v-btn>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import HeaderLabel from "../components/HeadetLabel";
import DragnDrop from "../components/input/DragnDrop";
import Service from "../services";
export default {
  name: "Banner",
  components: {
    HeaderLabel,
    DragnDrop,
  },
  data(){
    return{
      link:'',
    }
  },
  computed: {
    ...mapState({
      bannerList: (state) => state.Banner.bannerList,
      youtubeLink: (state) => state.Banner.youtubeLink,
    }),
  },
  created() {
    this.$store.dispatch("Banner/getBanner");
    this.$store.dispatch("Banner/getYoutubeLink");
    this.link = this.youtubeLink;
  },
  methods: {
    async saveLink(){
      this.$store.dispatch("Loading/onSetLoading", true);
      await Service.banner.addYoutubeLink({ link: this.link }).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch("Loading/onSetLoading", false);
          this.$swal.fire({
            ...this.$swalSuccess,
            text: "Save Youtube link Success",
          });
          this.$store.dispatch("Banner/getBanner");
        } else {
          this.$swal.fire({
            ...this.$swalSuccess,
            text: "Save Youtube link Error",
          });
          this.$store.dispatch("Loading/onSetLoading", false);
          this.$store.dispatch("Banner/getYoutubeLink");
          this.link = this.youtubeLink;
        }
      });
    },
    async uploadImage(index, result) {
      this.bannerList[index].image = result[0];
    },
    async saveBanner() {
      let img = this.bannerList.filter((x) => x.image != null);
      this.$store.dispatch("Loading/onSetLoading", true);
      await Service.banner.add({ bannerList: img }).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch("Loading/onSetLoading", false);
          this.$swal.fire({
            ...this.$swalSuccess,
            text: "Upload Banner Success",
          });
          this.$store.dispatch("Banner/getBanner");
        } else {
          this.$swal.fire({
            ...this.$swalSuccess,
            text: "Upload Banner Error",
          });
          this.$store.dispatch("Loading/onSetLoading", false);
        }
      });
    },
    remove(index) {
      this.bannerList.splice(index, 1);
    },
    add() {
      this.bannerList.push({
        description: null,
        url: null,
        image: null,
      });
    },
  },
};
</script>

<style>
</style>