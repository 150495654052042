
<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Part Management</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              v-model="part.sku"
              outlined
              :rules="rules"
              label="SKU"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="6">
            <v-text-field
              v-model="part.name"
              outlined
              :rules="rules"
              label="Name"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" :cols="!part.id ? '4' : '6'">
            <v-text-field
              v-model="part.price"
              outlined
              type="number"
              :rules="rules"
              label="Price (non vat)"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" :cols="!part.id ? '4' : '6'">
            <v-text-field
              v-model="part.weight"
              outlined
              type="number"
              :rules="rules"
              label="Weight"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="4">
            <v-text-field v-if="!part.id"
              v-model="part.stock"
              outlined
              type="number"
              :rules="rules"
              label="Stock"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="12">
            <v-textarea
              rows="3"
              dense
              v-model="part.description"
              outlined
              label="Description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Service from "../services/";
export default {
  name: "PartForm",
  props: {
    model: {
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    valid: true,
    part: {},
    rules: [(v) => !!v || "This Field is required"],
  }),
  created() {
    this.part.id = this.model.id;
    this.part.sku = this.model.sku;
    this.part.name = this.model.name;
    this.part.price = this.model.price;
    this.part.weight = this.model.weight;
    this.part.description = this.model.description;
    this.part.stock = this.model.product_stock.stock;
  },
  methods: {
    cancel() {
      this.$emit("SucessClick", null);
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.part.id) {
          //edit
          // this.$store.dispatch("Loading/onSetLoading", true);
          Service.part.edit(this.part).then((res) => {
            if (res.status === 201) {
              // this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            }
          });
        } else {
          //create
          // this.$store.dispatch("Loading/onSetLoading", true);
          Service.part.create(this.part).then((res) => {
            if (res.status === 200) {
              // this.$store.dispatch("Loading/onSetLoading", false);
              this.$emit("SucessClick", res.data);
            } else if (res.status === 500) {
              this.$swal.fire({
                ...this.$swalError,
                text: res.data.message,
              });
              // this.$store.dispatch("Loading/onSetLoading", false);
            }
          });
        }
      }
    },
  },
};
</script>

<style>
</style>
