import Service from '../../services'

const state ={
    groupPartList:[]
}

const getters={}
const actions={
    getgroupPartList({commit},model){
        return Service.groupPart.list(model).then((res)=>{
            if(res.status === 200){
                commit('setgroupPartList', res.data)
            }
        })
    }
}
const mutations={
    setgroupPartList(state,data){
        state.groupPartList = [];
        data.forEach(x => {
          x.image = x.image? JSON.parse(x.image):'';
          x.parts = x.parts? JSON.parse(x.parts):'';
        });
        state.groupPartList = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
