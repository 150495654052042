<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Parts"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-space-between mb-7>
          <v-flex shrink>
            <v-layout>
              <v-flex>
                <v-layout>
                  <v-flex>
                    <FilterDisplay @onChange="filter = $event"></FilterDisplay>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      class="mr-2"
                      v-model="keyword"
                      dense
                      outlined
                      label="Keyword"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-btn
                      @click="search"
                      depressed
                      dense
                      large
                      color="#c80404"
                      class="text-start white--text"
                    >
                      Search
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex shrink>
            <v-btn
              @click="clickRow(null)"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn>
          </v-flex>
        </v-layout>
        <v-row>
          <v-col>
            <v-data-table :items="partList" style="border: solid 1px black">
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Name</td>
                    <td>SKU</td>
                    <td>Price (THB) (non vat)</td>
                    <td>Weight (kg)</td>
                    <td>Stock</td>
                    <td>Description</td>
                    <td>Display</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr :class="{ itemHide: !item.is_active }">
                  <td>{{ item.name }}</td>
                  <td>{{ item.sku }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.weight }}</td>
                  <td>{{ item.product_stock.stock }}</td>
                  <td>{{ item.description }}</td>
                  <td :class="[item.is_active ? 'green--text' : 'grey--text']"> {{ item.is_active ? "Show" : "Hide" }}</td>
                  <td>
                    <a @click="clickStock(item)">Add Stock</a> |
                    <a @click="clickRow(item)">Edit</a> |
                    <a
                      :class="[item.is_active ? 'grey--text' : 'green--text']"
                      @click="toggleDisplay(item)"
                    >
                      {{ item.is_active ? "Hide" : "Show" }}
                    </a>
                    | <a @click="removeItem(item)" class="red--text">Remove</a>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <div v-if="dialog" style="background-color: white">
        <PartForm @SucessClick="SucessClick" :model="row"></PartForm>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogStock" max-width="700" persistent>
      <div v-if="dialogStock" style="background-color: white">
        <PartStockForm @SucessClick="stockClick" :model="stock"></PartStockForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import HeaderLabel from "../components/HeadetLabel";
import PartForm from "../components/PartForm";
import PartStockForm from "../components/PartStockForm";
import FilterDisplay from "../components/FilterDisplay";
import { mapState } from "vuex";
import Service from "../services";
export default {
  name: "Part",
  components: {
    PartForm,
    HeaderLabel,
    FilterDisplay,
    PartStockForm,
  },
  data() {
    return {
      row: {},
      keyword: null,
      stock: null,
      filter:1,
      dialog: false,
      dialogStock: false,
    };
  },
  methods: {
    async removeItem(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to Remove this Group Part?`,
      });
      if (answer.isConfirmed) {
        Service.part.delete(item.id).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
          }
        });
      }
    },
    search() {
      this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
    },
    stockClick() {
      this.dialogStock = false;
      this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
    },
    SucessClick() {
      this.dialog = false;
      this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
    },
    clickStock(item) {
      this.stock = item;
      this.dialogStock = true;
    },
    clickRow(item) {
      this.row = item
        ? item
        : {
            sku: null,
            name: null,
            price: null,
            description: null,
            product_stock: { stock: null },
          };
      this.dialog = true;
    },
    async toggleDisplay(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to ${item.is_active ? "hide" : "show"} this part?`,
      });
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading", true);
        Service.part.display(item).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
          }
        });
      }
    },
  },
  computed: {
    ...mapState({
      partList: (state) => state.Part.partList,
    }),
  },
  async created() {
    await this.$store.dispatch("Part/getPartList", {keyword:this.keyword, display:this.filter});
  },
};
</script>

<style>
</style>