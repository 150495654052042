<template>
  <div>
    <v-progress-linear
      v-if="imgLoading"
      indeterminate
      color="green"
    ></v-progress-linear>
    <div
      type="file"
      class="drop"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
      @click="$refs.file.click()"
      @change="drop"
      v-if="!imgLoading"
    >
      <input type="file" ref="file" style="display: none" accept="image/*" />

      <draggable
        :list="imageSource"
        @start="dragging = true"
        @end="dragging = false"
        class="row"
        v-if="imageSource.length !== 0"
      >
        <div class="text-center col-12" v-for="(item, index) in imageSource" :key="index">
          <v-hover v-slot="{ hover }">
            <v-img class="img-drop mx-auto" :src="item">
              <div
                v-if="hover || showHoverOnMobile"
                class="animate__animated animate__bounceIn pb-3 flex-center-row fill-height align-end"
                @click.stop
              >
                <v-btn
                  class="mb-2 mr-2"
                  elevation="0"
                  min-width="0"
                  small
                  @click="removeImg(index)"
                >
                  <span>REMOVE</span>
                </v-btn>
              </div>
            </v-img>
          </v-hover>
        </div>
      </draggable>
      <h1 v-if="wrongFile">Wrong file type</h1>
            <div class="fill-height text-center">
        <v-img
          v-if="imageSource.length == 0 && !wrongFile"
          :src="require(`../../assets/img/upload.png`)"
          height="150"
          contain
          class="pointer"
        >
        </v-img>
      </div>
    </div>
  </div>
</template>



<script>
import draggable from "vuedraggable";
import Service from "../../services";
import { api_domain } from "../../config";
export default {
  name: "DragnDrop",
  props: {
    imgList: {
      type: Array,
    },
    locationType:{
      type:String
    }
  },
  components: {
    draggable,
  },
  data() {
    return {
      isDragging: false,
      dragging: false,
      wrongFile: false,
      imgLoading: false,
      imageSource: this.imgList,
      showHoverOnMobile: false,
    };
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging };
    },
  },
  methods: {
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    removeImg(index) {
      this.imageSource.splice(index, 1);
      this.$emit("updateImgProduct", this.imageSource);
    },
    async drop(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.wrongFile = false;
      this.imgLoading = true;
      // allows only 1 file
      if (files.length === 1) {
        let file = files[0];
        // allows image only
        if (file.type.indexOf("image/") >= 0) {
          let form = new FormData();
          form.append('type', this.locationType)
          form.append("image", file);
          let result = await Service.upload.uploadImage(form);
          if (result.status === 200) {
            let imgURL = api_domain + result.data;
            this.imageSource = []
            this.imageSource.push(imgURL);
             this.$emit('updateImgProduct', this.imageSource)
            this.isDragging = false
            this.imgLoading = false;
          } else {
            this.imgLoading = false;
            this.wrongFile = true;
            this.imageSource = null;
            this.isDragging = false;
          }
        }
      }
    },
  },
};
</script>



<style scoped>
.drop {
  width: 100%;
  height: 100%;
  background-color: #eee;
  border: 10px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
  font-family: sans-serif;
  cursor: pointer;
}
.isDragging {
  background-color: #999;
  border-color: #fff;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.img-drop {
  max-width: 500px;
  max-height: 500px;
}
</style>



