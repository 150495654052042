<template>
  <v-alert class="text-left" border="left" colored-border color="#c80404" elevation="2">
    <h3 class="font-weight-bold">{{label}}</h3>
  </v-alert>
</template>

<script>
export default {
    name:'HeaderLabel',
    props:{
        label:{
            type:String
        }
    }
};
</script>

<style>
</style>