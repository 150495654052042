<template>
  <v-main>
    <v-layout column style="width: 280px">
      <v-flex class="cust-underline">
        <div class="py-5 px-12 mb-3 d-flex align-center justify-center">
          <img :src="require('../assets/logo.jpg')" style="width: 100%" />
        </div>

        <v-layout justify-start align-center mb-4 px-3>
          <v-flex shrink mr-2>
            <img
              :src="require('../assets/profile.png')"
              style="border-radius: 100%; width: 45px; height: 45px"
            />
          </v-flex>
          <v-flex>{{ userDetail.firstname }} {{ userDetail.lastname }}</v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <v-treeview
          id="side-menu"
          class="text-start cust-color"
          :items="menulist"
          open-on-click
          @update:active="menuChange"
          activatable
          color="#c80404"
          hoverable
        ></v-treeview>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Sidebar",
  computed: {
    ...mapState({
      userDetail: (state) => state.Auth.userDetail,
    }),
  },
  data: () => ({
    menulist: [
      {
        id: 1,
        name: "Product",
        children: [
          { id: 2, name: "Brand" },
          { id: 3, name: "CC" },
          { id: 4, name: "Motor Model" },
          { id: 6, name: "Group Parts" },
          { id: 7, name: "Part" },
        ],
      },
      {
        id: 12,
        name: "Orders",
      },
      {
        id: 14,
        name: "User Accounts",
      },
      {
        id: 8,
        name: "Setting",
        children: [
          { id: 9, name: "Exchange Rate" },
          { id: 15, name: "Shipping Fee" },
          { id: 10, name: "Banner" },
          { id: 13, name: "Account Payment" },
        ],
      },
      {
        id: 16,
        name: "Log out",
      },
    ],
  }),
  methods: {
    async logOut() {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: `Are you sure to Logout?`,
      });
      if (answer.isConfirmed) {
        this.routeTo("home");
        this.$store.dispatch("Loading/onSetLoading", true);
        await this.$store.dispatch("Auth/logOut");
        this.$store.dispatch("Loading/onSetLoading", false);
      }
    },
    routeTo(name) {
      if (this.$route.name !== name) this.$router.push({ name: name });
    },
    menuChange(id) {
      switch (id[0]) {
        case 2:
          this.routeTo("brand");
          break;
        case 3:
          this.routeTo("cc");
          break;
        case 4:
          this.routeTo("motor-model");
          break;
        case 6:
          this.routeTo("group-part");
          break;
        case 7:
          this.routeTo("part");
          break;
        case 9:
          this.routeTo("exchange-rate");
          break;
        case 10:
          this.routeTo("banner");
          break;
        case 11:
          this.routeTo("shop-config");
          break;
        case 12:
          this.routeTo("customer-order");
          break;
        case 13:
          this.routeTo("account-payment");
          break;
        case 14:
          this.routeTo("user-account");
          break;
        case 15:
          this.routeTo("shipping-fee");
          break;
        case 16:
          this.logOut();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.v-application--is-ltr .v-treeview .v-treeview-node--leaf {
  margin-left: 0 !important;
}
</style>
