import { api_domain } from '../../config'
import axios from 'axios'

const upload={
    uploadImage(formdata){
        return axios({
            url:`${api_domain}api/upload-image`,
            method:'POST',
            data:formdata
        }).catch((e)=>{
            console.log('Catch error on upload-image\n', e.message)
            return { status: e.response.status }           
        })
    }
}

export default upload;