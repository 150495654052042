import { api_domain } from '../../config'
import axios from 'axios'

const url = `${api_domain}api/shipping`
const shipping = {
    get(){
        return axios({
            url: url,
            method:'GET',
        }).catch((e)=>{
            console.log('Catch error on get-shipping\n', e.message)
            return { status: e.response.status }           
        })
    },
    create(payload){
        return axios({
            url: url,
            data:payload,
            method:'POST',
        }).catch((e)=>{
            console.log('Catch error on create-shipping\n', e.message)
            return e.response         
        })
    },
    edit(payload){
        return axios({
            url: url+'/update',
            data:payload,
            method:'PUT',
        }).catch((e)=>{
            console.log('Catch error on edit-shipping\n', e.message)
            return e.response         
        })
    },
    delete(payload){
        return axios({
            url: url+'-delete',
            data:payload,
            method:'DELETE',
        }).catch((e)=>{
            console.log('Catch error on delete-shipping\n', e.message)
            return e.response         
        })
    },
}
export default shipping