<template>
  <v-main style="height: 100%">
    <HeaderLabel label="User Account"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-end mb-7>
          <v-flex shrink>
            <!-- <v-btn
              @click="clickRow({id:null, firstname:null, lastname:null, email:null, phone:null, level:null})"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn> -->
          </v-flex>
        </v-layout>
        <v-col cols="12">
          <v-data-table :items="customerList" style="border: solid 1px black">
            <template v-slot:header="{}">
              <thead class="white--text" style="background-color: #555555">
                <tr>
                  <td>Email</td>
                  <td>First Name</td>
                  <td>Last Name</td>
                  <td>Tel Phone</td>
                  <td>Level</td>
                  <td>Login by</td>
                  <td></td>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td align="center">{{ item.email }}</td>
                <td>{{ item.firstname }}</td>
                <td>{{ item.lastname }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.level.value }}</td>
                <td>{{ item.fb_id ? "Facebook" : "Normal" }}</td>
                <td>
                  <a @click="clickRow(item)">Edit</a> 
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" @close="SucessClick" max-width="700" persistent>
      <div v-if="dialog" style="background-color: white">
        <UserAccountForm
          @SucessClick="SucessClick"
          :model="row"
        ></UserAccountForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import Service from '../services'
import HeaderLabel from "../components/HeadetLabel";
import UserAccountForm from "../components/UserAccountForm";
import { mapState } from "vuex";
export default {
  name: "UserAccount",
  components: {
    HeaderLabel,
    UserAccountForm,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      customerList: (state) => state.Customer.customerList,
    }),
  },
  methods: {
    SucessClick() {
      this.$store.dispatch("Customer/getCustomerList");
      this.dialog = false;
    },
    async removeAccount(item) {
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: "Are you sure to remove this account ?",
      });
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading",true);
        Service.customer.deleteCustomer(item).then((res) => {
          if (res.status === 201) {
            this.$store.dispatch("Loading/onSetLoading",false);
            this.$store.dispatch("Customer/getCustomerList");
          }
        });
      }
    },
    clickRow(item) {
      this.row = item;
      this.dialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("Customer/getCustomerList");
  },
};
</script>

<style>
</style>