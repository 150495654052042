<template>
  <div>
    <v-alert dark color="black">
      <h3 class="headline">Tracking Number</h3>
    </v-alert>
    <div class="pa-3">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="pt-3 pb-0" cols="12">
            <v-text-field
              v-model="model.number"
              outlined
              :rules="rules"
              label="Tracking Number"
              required
            ></v-text-field>
          </v-col>
          <v-col class="pt-3 pb-0" cols="12">
            <!-- <v-text-field
              v-model="model.transport"
              outlined
              :rules="rules"
              label="Transport"
              required
            ></v-text-field> -->
            <v-autocomplete
              solo
              flat
              v-model="model.transport"
              :rules="rules"
              outlined
              item-text="name"
              item-value="name"
              :items="transportList"
              label="Transport"
              required
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col cols="6">
            <v-btn block class="cust-border-btn-color" @click="cancel">
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :disabled="!valid"
              block
              class="cust-btn-color"
              @click="validate"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import Service from "../services/";
export default {
  props: {
    id: {
      default: 0,
    },
    data: {
      default: () => {
        return { number: "", transport: "" };
      },
    },
  },
  created() {
    this.model = this.data;
  },
  data: () => ({
    valid: true,
    transportList: [
      { header: "ภายในประเทศ" },
      { name: "Thailand Post", group: "ภายในประเทศ" },
      { name: "Kerry", group: "ภายในประเทศ" },
      { name: "Best Express", group: "ภายในประเทศ" },
      { name: "Ninja Van", group: "ภายในประเทศ" },
      { name: "J&T Express", group: "ภายในประเทศ" },
      { name: "Flash Express", group: "ภายในประเทศ" },
      { name: "SCG Express", group: "ภายในประเทศ" },
      { name: "DHL Express", group: "ภายในประเทศ" },
      { name: "LalaMove", group: "ภายในประเทศ" },
      { name: "Deliveree", group: "ภายในประเทศ" },
      { divider: true },
      { header: "ต่างประเทศ" },
      { name: "Thailand Post", group: "ต่างประเทศ" },
      { name: "DHL", group: "ต่างประเทศ" },
      { name: "FedEx", group: "ต่างประเทศ" },
      { name: "UPS", group: "ต่างประเทศ" },
      { name: "Fast Ship", group: "ต่างประเทศ" },
      { name: "Inter Express Logistics", group: "ต่างประเทศ" },
    ],
    model: {
      number: "",
      transport: "",
    },
    rules: [(v) => !!v || "This Field is required"],
  }),
  methods: {
    cancel() {
      this.$emit("SucessClick", null);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.model.id = this.id;
        this.$store.dispatch("Loading/onSetLoading", true);
        Service.order.updateTrackingNumber(this.model).then((res) => {
          if (res.status === 200) {
            this.$store.dispatch("Loading/onSetLoading", false);
            this.$emit("SucessClick", null);
          }
        });
      }
    },
  },
};
</script>

<style>
</style>