import { api_domain } from '../../config'
import axios from 'axios'

const auth = {
  fbRegister(data) {
    return axios({
      url: api_domain + 'api/fb-register',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on fbRegister\n', e.message)
      return { status: e.response.status }
    })
  },

  login(data){
    return axios({
      url: api_domain + 'api/login',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on login\n', e.message)
      return e.response
    })
  },

  register(data){
    return axios({
      url: api_domain + 'api/register',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on register\n', e.message)
      return e.response 
    })
  },

  editProfile(data){
    return axios({
      url: api_domain + 'api/edit-profile',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on editProfile\n', e.message)
      return { status: e.response.status }
    })
  },

  changePassword(data){
    return axios({
      url: api_domain + 'api/change-password',
      data: data,
      method: 'POST'
    }).catch((e) => {
      console.log('Catch error on changePassword\n', e.message)
      return { status: e.response.status }
    })
  },
}

export default auth
