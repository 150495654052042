<template>
  <v-main style="height: 100%">
    <HeaderLabel label="Account Payment"></HeaderLabel>
    <v-layout justify-center mt-5>
      <v-flex pa-4>
        <v-layout justify-end mb-7>
          <v-flex shrink>
            <v-btn
              @click="clickRow({id:null, account_name:null, account_number:null ,nice_name:null, currency:null })"
              depressed
              large
              color="#c80404"
              class="pr-7 text-start white--text"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add
            </v-btn>
          </v-flex>
        </v-layout>

        <v-row>
          <v-col cols="12">
            <v-data-table :items="bankTo" style="border: solid 1px black">
              <template v-slot:header="{}">
                <thead class="white--text" style="background-color: #555555">
                  <tr>
                    <td>Bank Name</td>
                    <td>Account Name</td>
                    <td>Account Number</td>
                    <td></td>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td align="center">{{ item.nice_name }}</td>
                  <td>{{ item.account_name }}</td>
                  <td>{{ item.account_number }}</td>
                  <td><a @click="clickRow(item)">Edit</a> | <a class="red--text" @click="removeAccount(item)">Delete</a></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" @close="SucessClick" max-width="700" persistent>
      <div v-if="dialog" style="background-color: white">
        <AccountPaymenForm
          @SucessClick="SucessClick"
          :model="row"
        ></AccountPaymenForm>
      </div>
    </v-dialog>
  </v-main>
</template>

<script>
import AccountPaymenForm from "../components/AccountPaymentForm";
import HeaderLabel from "../components/HeadetLabel";
import Service from '../services'
import { mapState } from "vuex";
export default {
  components: {
    HeaderLabel,
    AccountPaymenForm,
  },
  data(){
    return{
      dialog:false,
    }
  },
  computed: {
    ...mapState({
      bankTo: (state) => state.Payment.bankTo,
    }),
  },
  methods: {
    async removeAccount(item){
      let answer = await this.$swal.fire({
        ...this.$swalDel,
        text: 'Are you sure to remove this account ?'
      })
      if (answer.isConfirmed) {
        this.$store.dispatch("Loading/onSetLoading",true);
        Service.payment.deleteAccount(item).then((res)=>{
          if(res.status === 201){
            this.$store.dispatch("Loading/onSetLoading",false);
            this.$store.dispatch("Payment/getBankList");
          }
        })
      }
    },
    SucessClick(){
      this.$store.dispatch("Payment/getBankList");
      this.dialog = false;
    },
    clickRow(item) {
      console.log(item);
      this.row = item;
      this.dialog = true;
    },
  },
  mounted() {
    this.$store.dispatch("Payment/getBankList");
  },
};
</script>

<style>
</style>